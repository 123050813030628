import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../components/Button';

const Corporation = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = async (formData) => {
    try {
      const submissionData = { ...formData, category: 'Contact Us' };
      
      submissionData.company = formData.companyName; // Adjusted name to match the form field
      submissionData.message = formData.message;

      console.log(submissionData);

      await fetch(
        'https://script.google.com/macros/s/AKfycbxJ1QeSTIrHzvtVHCIeCYo7pHyZ4mesfOTRtOaUEv2KgOdVsUcr12y7ckMI3xuoUtEw/exec',
        {
          method: 'POST',
          mode: 'no-cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(submissionData),
        }
      );

      setShowSuccessMessage(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Form component
  const form = (
    <form style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem', // Adds space between elements
      width: '100%',
    }}>
      <label style={{ 
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80%',
        margin: '0 auto',
        textAlign: 'left',
      }}>*Name:
        <input type="text" 
        name="name" 
        placeholder="e.g., Jane Doe" 
        required 
        style={{
          fontSize: '1rem',
          width: 'calc(100% - 1rem - 2px)',
          alignItems: 'center',
          padding: '0.5rem',
          marginTop: '0.5rem',
          borderRadius: '5px',
          border: '1px solid #ccc',
        }}/>
      </label>
      <label style={{ 
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '0 auto',
        textAlign: 'left',
        width: '80%',
      }}>*Email:
        <input type="email" 
        name="email" 
        placeholder='e.g., joeblow@gmail.com' 
        required 
        onKeyDown={(e) => {
          // Prevent the user from pressing the spacebar
          if (e.key === ' ') e.preventDefault();
        }}
        onChange={(e) => {
          // Remove any spaces (in case of copy/paste)
          e.target.value = e.target.value.replace(/\s+/g, '');
        }}
        style={{
          fontSize: '1rem',
          width: 'calc(100% - 1rem - 2px)',
          padding: '0.5rem',
          marginTop: '0.5rem',
          borderRadius: '5px',
          border: '1px solid #ccc',
        }}
      />
      </label>
      <label style={{ 
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '0 auto',
        textAlign: 'left',
        width: '80%',
      }}>*Company Name:
        <input 
        type="text" 
        name="companyName" 
        placeholder="e.g., Acme Corp" 
        required 
        style={{
          fontSize: '1rem',
          width: 'calc(100% - 1rem - 2px)',
          padding: '0.5rem',
          marginTop: '0.5rem',
          borderRadius: '5px',
          border: '1px solid #ccc',
        }} />
      </label>
      <label style={{ 
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '0 auto',
        textAlign: 'left',
        width: '80%',
      }}>Message:
        <textarea name="message" placeholder="Write your message here..." style={{
          fontSize: '1rem',
          width: 'calc(100% - 1rem - 2px)',
          padding: '0.5rem',
          marginTop: '0.5rem',
          borderRadius: '5px',
          border: '1px solid #ccc',
          resize: 'none',
          height: '100px', // Set height for the textarea
        }} />
      </label>
      <Button label="Submit" style={{width: '90%'}}  type="Submit" />
    </form>
  );

  const clonedForm = React.cloneElement(form, {
    onSubmit: async (event) => {
      event.preventDefault();
      const formData = Object.fromEntries(new FormData(event.target).entries());
      await handleFormSubmit(formData);
    },
  });

  return (
    <div
      style={{
        position: 'relative',
        width: '100vw',
        minHeight: '100vh',
        backgroundColor: '#100F1C',
      }}
    >
      <div style={{
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        fontFamily: '"Inter", system-ui, sans-serif',
        color: '#ffffff',
        padding: '0 20px',
        boxSizing: 'border-box',
        textAlign: 'center',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '1rem', 
        }}>
          <h1 style={{
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#ffffff',
            textTransform: 'uppercase',
            letterSpacing: '0.1rem',
            marginBottom: '0.5rem',
          }}>
            Contact Us
          </h1>
          <div style={{
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxWidth: '1200px',
            margin: '0 auto',
          }}>
            {showSuccessMessage ? (
              <div style={{
                background: 'rgba(249,250,253,255)',
                padding: '2rem',
                borderRadius: '1rem',
                flex: '1',
                minWidth: '300px',
                maxWidth: '700px',
                backdropFilter: 'blur(10px)', // For most browsers
                WebkitBackdropFilter: 'blur(10px)', // For Safari on iOS
                border: '1px solid rgba(99, 102, 241, 0.2)',
              }}>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ width: '100%', height: '600px', border: 'none' }}>
                    <iframe
                      src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2ROL7aiGox8paItsW1ycgspxnClpCmi1s7C3PmU9nQrr2Kwz0Fte3aeFizEbDvvYkorkWI1xYB?gv=true"
                      style={{ border: 0, width: '100%', height: '100%' }}
                      frameBorder="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div style={{
                  background: 'rgba(99, 102, 241, 0.1)',
                  padding: '2rem',
                  borderRadius: '1rem',
                  flex: '1',
                  minWidth: '300px',
                  maxWidth: '500px',
                  backdropFilter: 'blur(10px)', // For most browsers
                  WebkitBackdropFilter: 'blur(10px)', // For Safari on iOS
                  border: '1px solid rgba(99, 102, 241, 0.2)',
                }}>
                  <h2 style={{
                    fontSize: '1.8rem',
                    marginBottom: '1rem',
                    color: '#ffffff',
                  }}>Corporate Project Interest</h2>
                  <div style={{
                    color: '#a5b4fc',
                    marginBottom: '1.5rem',
                  }}>
                    <div style={{
                      color: '#a5b4fc',
                      marginBottom: '1.5rem',
                    }}></div>
                    <p style={{
                      fontSize: '1.1rem',
                      lineHeight: '1.5',
                    }}>
                      If you want to learn more about what we're doing, express interest, or just network, please fill out the form below.
                    </p>
                    {clonedForm}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Corporation;