import React from 'react';

const Header = () => {
  return (
    <header
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '5rem',
        backgroundColor: 'rgba(16, 15, 28, 0.9)', // Semi-transparent background
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 99, // Ensure it's above other elements
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for separation
        padding: '0 1rem',
        boxSizing: 'border-box',
      }}
    >
      {/* Logo / Home Link */}
      <a
        href="/"
        style={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
        }}
      >
        <img
          src="/FullLogo_Transparent_NoBuffer.png"
          alt="Do Quantum Logo"
          style={{
            height: '3.5rem',
            cursor: 'pointer',
          }}
        />
      </a>
    </header>
  );
};

export default Header;