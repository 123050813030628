import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import Corporation from './pages/Corporation';
import Professor from './pages/Professor';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/corporation" element={<Corporation />} />
        <Route path="/professor" element={<Professor />} />
        
        <Route path="*" element={<h1 style={{color: '#ffffff', }}>404 Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;